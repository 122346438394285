import React, { useEffect, useContext } from "react";
import { graphql } from "gatsby";
import Markdown from "markdown-to-jsx";
import Layout from "../components/layout/layout";
import SEO from "../components/layout/seo";
import { domain, formatRichText } from "../utils";
import { Subscription } from "../components/partials/subscription/subscription";
import { GlobalDispatchContext } from "../context/GlobalContextProvider";
import { Blog } from "../components/partials/blog/blog";
import $ from 'jquery';

const BlogItemPage = ({ data }) => {
  const { blogitem, homepages } = data.strapi;
  const {
    header,
    footer,
    subscriptionHeading,
    subscriptionDescription,
  } = homepages[0];
  const { language, image, publishing_date, title, author, richText, meta } = blogitem;
  const {blogitems} = data.strapi;

  const content = formatRichText(richText);
  const dispatch = useContext(GlobalDispatchContext);

  useEffect(() => {
    dispatch({ type: "SET_LANGUAGE", language });
    
    if (!$('p:has(img)').hasClass('text-center')) {
      $('p:has(img)').addClass('text-center');
    }
  });


  let relatedBlogItems = [];
  blogitems.map(item => {
    if (item.title !== blogitem.title) {
      relatedBlogItems.push(item);
    }
  });
  return (
    <Layout header={header} footer={footer}>
      <SEO title={meta.title} description={meta.description}/>
      <div className="px-md-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md-10 offset-md-1 mb-3 mb-md-5 text-center">
              <img
                className="mw-100"
                alt="blogImage"
                src={`${domain}${image.url}`}
              />
            </div>
          </div>
        </div>
        <div className="container">
          <div className="mt-3">
            <div className="col-12 p-0 text-center">
              <div className="small">
                <small>{publishing_date.slice(0, 10)}</small>
              </div>
              <h1>{title}</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row mt-2 mb-5">
          <div className="col-12 text-center">
            <div className="d-table mx-auto">
              <div className="thumbnail t-cell-middle">
                <img
                  className="rounded-circle mr-3"
                  alt="author"
                  src={`${domain}${author && author.picture.url}`}
                />
              </div>
              <div className="text-left small t-cell-middle">
                <div className="font-weight-bold">{author && author.name}</div>
                <p className="m-0">{author && author.role}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container blog-content markdown">
        <div className="row mb-5" data-aos="fade-up" data-aos-once="true">
          <div className="col-12 col-md-10 offset-md-1 text-left">
            <Markdown>{content.split('(/').join(`(${domain}/`)}</Markdown>
          </div>
        </div>
      </div>
      <Subscription
        subscriptionHeading={subscriptionHeading}
        subscriptionDescription={subscriptionDescription}
      />
      {blogitems && blogitems.length > 0 && (
        <div>
          <Blog blogHeading={language === 'en' ? 'Related articles' : 'Ähnliche Artikel'} blogItems={relatedBlogItems} />
        </div>
      )}
    </Layout>
  )
}

export default BlogItemPage

export const query = graphql`
  query BlogItemPage($id: ID!, $language: STRAPI_JSON!) {
    strapi {
      blogitems(sort: "publishing_date:DESC", where: $language) {
        slug
        language
        title
        publishing_date
        image {
          url
        }    
      }
      blogitem(id: $id) {
        language
        image {
          url
        }
        publishing_date
        title
        author {
          name
          role
          picture {
            url
          }
        }
        richText
        meta {
          title
          description
        }
      }
      homepages(where: $language) {
        header {
          logo {
            url
          }
          links {
            label
            slug
          }
        }
        footer {
          title
          copyright
          logo {
            url
          }
          nav {
            heading
            link {
              label
              slug
            }
          }
        }
        subscriptionHeading
        subscriptionDescription
      }
    }
  }
`
